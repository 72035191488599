@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
	font-family: "Basement Grotesque Roman";
	src: url("./Fonts/BasementGrotesque-Black.woff2") format("woff2"),
		url("./Fonts/BasementGrotesque-Black.woff") format("woff"),
		url("./Fonts/BasementGrotesque-Black.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
p,
button,
br,
img,
a {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
	color: rgb(207, 207, 207);
	text-decoration: none;
}

header {
	width: 100%;
	height: 100vh;
	background: rgb(22, 22, 22);
	background: url("./Assets/header0_4.png");
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
	position: relative;
	padding: 15rem 0;
	.blur {
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(
			to bottom,
			rgba(22, 22, 22, 0.1) 0%,
			rgba(22, 22, 22, 0.6) 75%,
			#161616 100%
		);
		backdrop-filter: blur(3px);
		height: 100vh;
		width: 100%;
		z-index: 0;
	}
	nav {
		// z-index: 2;
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 15px 30px;
		ul {
			display: flex;
			li {
				a {
					margin: 10px;
					color: white;
					font-size: 17px;
					font-weight: 400;
					letter-spacing: 1px;
				}
			}
		}
	}
	.readmore {
		position: absolute;
		bottom: 2%;
		left: 50%;
		transform: translate(0% - 50%);
		font-size: 15px;
		color: white;
		text-decoration: none;
		font-family: "Basement Grotesque Roman";
		letter-spacing: 1px;
		z-index: 1;
	}
	.header-text {
		z-index: 1;
		width: 100%;
		height: 100%;
		text-align: center;
		position: relative;
		h1 {
			font-family: Roboto;
			font-size: 95px;
			font-weight: 300;
			letter-spacing: 1px;
		}
		h2 {
			font-family: Roboto;
			letter-spacing: 2px;
			font-size: 20px;
		}
		button {
			border: none;
			border-radius: 10px;
			background-color: #15b43d;
			color: white;
			padding: 10px 15px;
			font-size: 18px;
			margin-top: 10px;
			box-shadow: #0c8b2c 5px 5px 0;
			cursor: pointer;
			&:active {
				background-color: #139e36;
			}
		}
	}
	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		background-color: rgba($color: #000000, $alpha: 0.5);
		padding: 20px 40px;
		border-radius: 10px;
		z-index: 999;
		-webkit-user-select: all; /* Safari */
		-moz-user-select: all; /* Firefox */
		-ms-user-select: all; /* IE10+/Edge */
		user-select: all; /* Standard */
		h6 {
			color: white;
			cursor: text;
			z-index: 999;
			font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
		}
		h5 {
			font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px 10px;
		}
	}
}

.bg1 {
	background: linear-gradient(180deg, rgb(22, 22, 22) 0%, #333333 100%);
}
.bg2 {
	background: linear-gradient(0deg, rgb(22, 22, 22) 0%, #333333 100%);
}

main {
	width: 100%;
	height: auto;
	padding: 3rem 0;
	div {
		div {
			width: 100%;
			height: 100%;
			img {
				width: 80%;
				height: 100%;
				border: 2px solid white;
				border-radius: 3px;
				box-shadow: black 0px 5px 30px;
				margin-bottom: 10px;
			}
			h2 {
				letter-spacing: 1px;
				font-size: 55px;
				margin: 25px 0;
				font-family: "Basement Grotesque Roman";
				color: rgb(236, 236, 236);
			}
			p {
				font-family: "Basement Grotesque Roman";
				font-size: 18px;
				letter-spacing: 1px;
				margin: 4px 0;
			}
		}
	}
}

section {
	width: 100%;
	height: auto;
	div > div {
		div {
			align-self: center;
			flex-direction: column;
			justify-self: center;
			width: 100%;
			h2 {
				display: block;
				font-family: "Basement Grotesque Roman";
				letter-spacing: 1px;
			}
			p {
				display: block;
				font-family: "Basement Grotesque Roman";
				letter-spacing: 1px;
				margin: 10px 0px;
				line-height: 130%;
				// max-width: 90%;
			}
		}
	}
	.image {
		grid-area: a;
	}
	.imageText {
		grid-area: b;
		padding: 20px;
	}
}

#Elitemobs {
	padding-bottom: 30px;
}

footer {
	width: 100%;
	height: auto;
	background-color: #161616;
	padding: 10px;
	display: flex;
	ul {
		padding: 5px 10px;
		li {
			margin: 10px;
		}
	}
}

.grid1,
.grid2 {
	display: grid;
	width: 100%;
	height: 100%;
	padding: 10px;
	img {
		width: 100%;
		height: auto;
		border: 2px solid white;
		border-radius: 3px;
		box-shadow: black 0px 5px 30px;
	}
}

.italic {
	font-style: italic;
}

.align-items {
	display: flex;
	align-items: center;
}

.text-align-center {
	text-align: center;
}

.container,
.container-xl,
.container-l,
.container-m,
.container-s {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}

@media (min-width: 0px) {
	main {
		padding: 2rem 0;
		div {
			div {
				img {
					width: 100%;
				}
				h2 {
					font-size: 5.5vw;
				}
				p {
					font-size: 2.4vw;
				}
			}
		}
	}

	.grid1,
	.grid2 {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			"a"
			"b";
		gap: 0;
		.imageText {
			background: none;
			padding-bottom: 0;
			h2 {
				font-size: 5vw;
			}
			p {
				font-size: 2vw;
				max-width: 80%;
			}
		}
		.image {
			width: 100%;
		}
	}
}

@media (min-width: 576px) {
	.container,
	.container-s {
		max-width: 540px;
	}

	main {
		div {
			div {
				img {
					width: 100%;
				}
				h2 {
					font-size: 28px;
				}
				p {
					font-size: 13px;
				}
			}
		}
	}

	.grid1,
	.grid2 {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			"a"
			"b";
		gap: 0;
		.imageText {
			background: none;
			padding-bottom: 0;
			h2 {
				font-size: 20px;
			}
			p {
				font-size: 10px;
			}
		}
		.image {
			width: 80%;
		}
	}
}
@media (min-width: 768px) {
	.container,
	.container-m,
	.container-s {
		max-width: 720px;
	}

	main {
		div {
			div {
				img {
					width: 80%;
				}
				h2 {
					font-size: 35px;
				}
				p {
					font-size: 14px;
				}
			}
		}
	}

	.grid1,
	.grid2 {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas:
			"a"
			"b";
		gap: 0;
		.imageText {
			background: none;
			h2 {
				font-size: 25px;
			}
			p {
				font-size: 13px;
			}
		}
		.image {
			width: 80%;
		}
	}
}
@media (min-width: 992px) {
	.container,
	.container-l,
	.container-m,
	.container-s {
		max-width: 960px;
	}

	.grid1,
	.grid2 {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 5%;
		grid-template-areas: "a b";
		.image {
			width: 100%;
		}
		.imageText {
			background: radial-gradient(
				circle,
				rgb(22, 22, 22) 10%,
				rgba(0, 0, 0, 0) 100%
			);
			padding-bottom: 20px;
			h2 {
				font-size: 25px;
			}
			p {
				font-size: 13px;
				max-width: 90%;
			}
		}
	}
	.grid2 {
		grid-template-areas: "b a";
	}
}
@media (min-width: 1200px) {
	.container,
	.container-xl,
	.container-l,
	.container-m,
	.container-s {
		max-width: 1140px;
	}

	main {
		padding: 3rem 0;
		div {
			div {
				img {
					width: 80%;
				}
				h2 {
					font-size: 55px;
				}
				p {
					font-size: 18px;
				}
			}
		}
	}

	.grid1,
	.grid2 {
		.imageText {
			h2 {
				font-size: 30px;
			}
			p {
				font-size: 15px;
			}
		}
	}
}
@media (min-width: 1450px) {
	.container,
	.container-xxl,
	.container-xl,
	.container-l,
	.container-m,
	.container-s {
		max-width: 1300px;
	}
}
